import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

const smoothScroller = () => {
  const featurettes = document.querySelector("#featurettes");
  window.scroll({
    top: featurettes.offsetTop - 60,
    left: 0,
    behavior: "smooth"
  });
};

$(document).ready(() => {
  const homeArrow = document.querySelector("#homeArrow");
  if (homeArrow) homeArrow.addEventListener("click", smoothScroller);
});
