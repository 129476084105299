$(document).ready(() => {
  // nav scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
        $(".arrow").addClass("arrow--scrolled");
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
        $(".arrow").removeClass("arrow--scrolled");
      }
    }
  });

  // menu
  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    $("#menu").css("margin-top", $("header").height());
    $("#menu").height($(window).height() - $("header").height());
    $("#menu").slideToggle(350);
    if ($(this).hasClass("hamburger--clicked")) {
      $("body").css("overflow", "hidden");
      $(".hamburger__line--2 small").html("Close");
      $(".navbar").addClass("navbar--opened");
      $("#search").hide();
      $("#searchBtn").removeClass("search--clicked");
      $("#searchOpen").show();
      $("#searchClose").hide();
    } else {
      $("body").css("overflow", "auto");
      $(".hamburger__line--2 small").html("Menu");
      $(".navbar").removeClass("navbar--opened");
    }
  });

  // search
  $("#searchBtn").on("click", function() {
    $(this).toggleClass("search--clicked");
    $("#search").height($(window).height() - $("header").height());
    $("#search").css("margin-top", $("header").height());
    $("#search").fadeToggle(350);
    if ($(this).hasClass("search--clicked")) {
      $("body").css("overflow", "hidden");
      $(".navbar").addClass("navbar--opened");
      $(".hamburger__line--2 small").html("Menu");
      $(".hamburger").removeClass("hamburger--clicked");
      $("#menu").hide();
      $("#searchOpen").hide();
      $("#searchClose").show();
    } else {
      $("body").css("overflow", "auto");
      $(".navbar").removeClass("navbar--opened");
      $("#searchOpen").show();
      $("#searchClose").hide();
    }
  });
});
